$blue:#003D5C;
$blue-text:#006699;
$green:#CBD34B;
$white:#ffffff;
$orange:#CC0000;

$xl:2159px;
$lg:1365px;
$md:1023px;
$sm:767px;
$xs:374px;

.blue-text{
	color: $blue-text;
}

h1{
	font-family: 'Montserrat', sans-serif;
	font-weight: 300;
	font-size: 6.3vw;
	line-height: 6.8vw; 
	&.white{
		color:white;
	}
	&.drop-shadow{
		text-shadow: 0 3px 6px rgba(0,0,0,0.6);
	}
}

h3{
	font-family: 'Montserrat', sans-serif;
	font-weight: 400;
	font-size: 1.6vw;
    line-height: 2vw;
	&.white{
		color:white;
	}
	&.drop-shadow{
		text-shadow: 0 3px 6px rgba(0,0,0,0.6);
	}
}

p{
	font-family: 'Montserrat', sans-serif;
	font-weight: normal;
	font-weight: 400;
	font-size: 18vw;
}