$light: #fff;
$blue: #003d5c;
$blue-text: #006699;
$green: #cbd34b;
$white: #ffffff;
$orange: #cc0000;
$dark: #0a0600;
$grey: #707070;
$success: #89a84f;

$body_fonts: "Montserrat", sans-serif;

$mobile_lg: 450px;
$mobile_sm: 370px;
$mobile_xsm: 320px;
