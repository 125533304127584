.footer {
  height: 100px;
  background-color: #333333;
  display: flex;
  align-items: center;
  p {
    font-size: 17px;
    color: $light;
    font-weight: 400;
    line-height: 1.3;
    margin-bottom: 0;
    @media (max-width: $mobile_sm) {
      font-size: 14px;
    }
  }
}
