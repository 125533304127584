@import "./variables.scss";

.info_wrap {
  height: calc(100vh - 100px);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 120px;
  padding-bottom: 150px;
  background-size: 100% 100%;
  h3 {
    font-size: 55px;
    font-weight: 400;
    color: $light;
    line-height: 1.2;
    margin-bottom: 30px;
    @media (max-width: $mobile_sm) {
      font-size: 42px;
    }
  }
  .btn_success {
    height: 47px;
    border: 2px solid $light;
    display: flex;
    align-items: center;
    padding: 5px 20px;
    border-radius: 25px;
    background-color: $green;
    font-size: 17px;
    font-weight: 500;
    color: $blue;
    text-align: left;
    width: 100%;
    &:focus {
      outline: none;
      box-shadow: none;
    }
    @media (max-width: $mobile_lg) {
      font-size: 16px;
      padding: 3px 15px;
      letter-spacing: -0.5px;
    }
    @media (max-width: $mobile_sm) {
      letter-spacing: -0.5px;
      font-size: 14px;
    }
    img {
      margin-left: auto;
      @media (max-width: $mobile_sm) {
        width: 15px;
      }
    }
  }
}
