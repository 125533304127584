@import "./_vars.scss";
#homeMenuHolder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-width: 100%;
  display: block;
  #homeMenu {
    #speaker {
      position: absolute;
      padding: 0;
      bottom: 40px;
      right: 40px;
      cursor: pointer;
      width: 50px;
      opacity: 0.8;
      .speakerBtn.off {
        display: none;
      }
      &.muted {
        opacity: 0.25;

        .speakerBtn.off {
          display: block;
        }
        .speakerBtn.on {
          display: none;
        }
      }
      &.unmuted {
        opacity: 0.8;

        .speakerBtn.off {
          display: none;
        }
        .speakerBtn.on {
          display: block;
        }
      }
      &:hover {
        opacity: 1;
      }
    }
    .video-holder {
      width: 100%;
      height: 100vh;
      position: absolute;
      pointer-events: none;
      max-width: 100%;
      overflow: hidden;
      background-size: cover;
      background-position: top;
      top: 0;
      left: 0;
      video {
        position: fixed;
        top: 0;
        left: 0;
        transform: none;
        background-image: url("./images/homePoster.jpg");
        background-size: cover;
        background-position: center;
        width: 100%;
        height: 100%;
      }
      video[poster] {
        object-fit: cover;
      }
    }

    .content-holder {
      .container {
        max-width: 2160px;
        padding: 24% 9%;
        @media (min-width: 2160px) {
          max-width: 3840px;
        }

        h3 {
          padding: 1.5vw 40% 0 0;
        }

        .menu-boxes {
          .col {
            padding: 0 25px;
            @media (max-width: 1023px) {
              padding: 0 15px;
            }

            .odd-ball {
              cursor: pointer;
              &:hover {
                .play {
                  background-color: $white !important;
                  &:after {
                    border-color: transparent transparent transparent $green !important;
                  }
                }
              }
            }

            .box-button {
              position: relative;
              cursor: pointer;
              height: 10.3vw;
              max-height: 237px;

              width: 100%;
              border: 2px solid $white;
              -webkit-border-radius: 0px 1.4vw 0px 1.4vw;
              border-radius: 0px 1.4vw 0px 1.4vw;
              padding: 8%;
              background-size: 100%;
              background-position: 50% 50%;
              background-repeat: no-repeat;

              transition: background-size 0.25s ease-out;
              -moz-transition: background-size 0.25s ease-out;
              -ms-transition: background-size 0.25s ease-out;
              -o-transition: background-size 0.25s ease-out;
              -webkit-transition: background-size 0.25s ease-out;

              &.no-border {
                pointer-events: none;
                border: none;
                &:after {
                  display: none;
                }
              }
              &.instr {
                p {
                  font-size: 20px;
                  margin: 0 0 5px 0;
                  text-shadow: 0 0 5px #242424;
                  text-transform: none;
                  @media (max-width: 1919px) {
                    font-size: 17px;
                  }
                  @media (max-width: 1600px) {
                    font-size: 14px;
                  }
                  @media (max-width: 1365px) {
                    font-size: 10px;
                  }
                  @media (max-width: 1023px) {
                    font-size: 6px;
                  }
                }
                .play {
                  background-color: $green;
                  width: 90px;
                  height: 90px;
                  position: absolute;
                  left: 0;
                  right: 0;
                  top: 0;
                  bottom: 0;
                  margin: auto;
                  -webkit-border-radius: 55px;
                  border-radius: 55px;
                  border: 2px solid $white;

                  transition: background-color 0.25s ease-out;
                  -moz-transition: background-color 0.25s ease-out;
                  -ms-transition: background-color 0.25s ease-out;
                  -o-transition: background-color 0.25s ease-out;
                  -webkit-transition: background-color 0.25s ease-out;

                  @media (max-width: 1600px) {
                    width: 55px;
                    height: 55px;
                    left: 10px;
                  }
                  @media (max-width: 1365px) {
                    width: 45px;
                    height: 45px;
                    left: 12px;
                  }

                  &:after {
                    content: "";
                    position: absolute;
                    left: 5px;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 12px 0 12px 21px;
                    border-color: transparent transparent transparent $white;
                  }
                }
              }

              p {
                position: absolute;
                bottom: 0;
                color: $white;
                font-size: 30px;
                font-family: "Montserrat", sans-serif;
                font-weight: 700;
                text-transform: uppercase;
                @media (max-width: 1600px) {
                  font-size: 1.5vw;
                }
              }
              &:after {
                content: "";
                background-image: url("./images/greenForwardArrow.png");
                background-repeat: no-repeat;
                background-position: 50%;
                background-size: 100%;
                position: absolute;
                bottom: 11%;
                right: 11%;
                height: 43px;
                width: 43px;
                //padding: 10px 50px;
                transition: right 0.25s;
                -webkit-transition: right 0.25s;

                @media (max-width: 1600px) {
                  background-size: 80%;
                  bottom: 8%;
                }

                @media (max-width: 1366px) {
                }
                @media (max-width: 1023px) {
                  background-size: 40%;
                  bottom: 5%;
                  right: 5%;
                }
              }
              &:hover {
                background-size: 140%;
                &:after {
                  right: 10px;
                  @media (max-width: 1023px) {
                    right: -5px;
                  }
                }
              }
            }
          }
        }
      }
    }
    .top-gradient {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 500px;
      background: -moz-linear-gradient(
        top,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 100%
      );
      background: -webkit-linear-gradient(
        top,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 100%
      );
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=0 );
      pointer-events: none;
    }
    .stagr-logo {
      position: absolute;
      display: block;
      top: 50px;
      left: 9%;
      width: 20vw;
      max-width: 409px;
      min-width: 200px;
      cursor: pointer;
    }
  }
}
