@import "./variables.scss";

.mobile_info_content {
    display: flex;
    align-items:center;
    justify-content:center;

.mobile_btn_info{
    width:350px;
    background-color: #CAD44B;
    color: #003D5B;
    border: none;
    padding: 15px 10px;
    border-radius: 20px;
    font-weight:bold;
    font-size: 16px;
}

}
