.popup_wrap {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  width: 100%;
  height: 100vh;
  overflow: auto;
  display: flex;
  transition: 0.5s all ease;
  flex-direction: column;
  transform: scale(0);
  .popup_head {
    height: 77px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    position: relative;
    .blur {
      filter: blur(8px);
      -webkit-filter: blur(8px);
      background-color: rgba(51, 51, 51, 0.95);
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }
  .close_popup {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 700;
    color: $light;
    z-index: 10;
    position: relative;
    border: none;
    background-color: transparent;
    padding: 0;
    margin-left: auto;
    &:focus {
      outline: none;
      box-shadow: none;
    }
    img {
      width: 20px;
      height: 20px;
      margin-left: 7px;
    }
  }

  .popup_inner {
    background-color: $light;
    min-height: calc(100vh - 74px);
    width: 100%;
    z-index: 5;
    position: relative;
    margin-top: -3px;
    padding: 25px 0 0 0;
    h4 {
      font-size: 34px;
      font-weight: 400;
      color: $blue-text;
      line-height: 1.2;
      margin-bottom: 20px;
      @media (max-width: $mobile_sm) {
        font-size: 26px;
      }
    }
  }

  ul {
    li {
      padding-left: 18px;
      position: relative;
      margin-bottom: 10px;
      &:before {
        content: "";
        width: 8px;
        height: 8px;
        background-color: $success;
        border-radius: 50%;
        display: inline-block;
        position: absolute;
        left: 0;
        top: 7px;
      }
      span {
        color: $green;

        display: inline-block;
        margin-bottom: 0;
        @media (max-width: $mobile_lg) {
          color: $success;
          a {
            color: $success;
          }
        }
      }
      p {
        margin-bottom: 5px;
      }
    }
    .sub_list,
    ul {
      li {
        &:first-child {
          margin-top: 12px;
        }
        &:before {
          border: 1px solid $success;
          background-color: transparent;
        }
      }
    }
    .sub_list li ul li:before {
      border: 0;
      content: '-';
      color: $success;
      line-height: 8px;
    }
  }
  .text_highlight {
    color: $success;
  }
  .lists {
    padding: 25px 0;
  }
  .bg-gray {
    background-color: #f3f3f3;
  }

  // Audio CSS
  .voice {
    margin-bottom: 15px;
    h5 {
      font-size: 21px;
      font-weight: 700;
      color: $blue-text;
      line-height: 1.2;
      margin-bottom: 10px;
    }
    .progress-bar {
      height: 4px !important;
      border-radius: 0 !important;
      background-color: $green !important;
    }
    .indicator {
      width: 11px !important;
      height: 21px !important;
      background-color: $blue-text !important;
      border-radius: 0 !important;
      top: -9px !important;
    }
    .react-h5-audio-player {
      > div {
        box-shadow: none !important;
        padding: 0 !important;
        align-items: center;
      }
    }
    .toggle-play-button {
      width: 36px !important;
      height: 36px !important;
      border-radius: 50% !important;
      background-color: $green !important;
      padding: 0 !important;
      flex: 0 0 36px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .play-icon {
      border-top: 10px solid transparent !important;
      border-bottom: 10px solid transparent !important;
      border-left: 18px solid $blue-text !important;
      left: auto !important;
    }
    .pause-icon {
      border-left: 6px solid $blue-text !important;
      background-color: $blue-text !important;
      box-shadow: #cbd34b 8px 0px 0px 0px inset !important;
      left: 0px !important;
    }
  }
  .progress-bar-wrapper {
    padding: 0 !important;
    height: auto !important;
    position: relative !important;
    flex: none !important;
    width: calc(100% - 50px) !important;
  }
  .toggle-play-wrapper {
    flex: none !important;
    width: 36px;
    height: 36px;
  }
  .audio-info {
    display: none !important;
  }

  // Modal Footer CSS
  .modal_footer {
    .info_wrap {
      height: auto;
      padding: 35px 0 135px;
      @media (max-width: $mobile_sm) {
        padding: 25px 0 60px;
      }
      h3 {
        font-size: 30px;
        font-weight: 500;
        line-height: 1.1;
        @media (max-width: $mobile_sm) {
          font-size: 24px;
          margin-bottom: 20px;
        }
      }
    }
  }
}

.modal_btn{
  padding: 50px 40px 100px;
}
.show-popup {
  transform: scale(1);
}
