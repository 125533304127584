@import "./variables.scss";

.bar_wrap {
    position: absolute;
    left: 0;
    bottom: 40px;
    display: inline-block;
    left: 0;
    z-index: 10;
    width: 100%;
  span {
    font-weight: 700;
    color: $light;
    margin-bottom: 5px;
    display: block;
    @media (max-width: $mobile_sm) {
      left: 15px;
      bottom: 30px;
      font-size: 16px;
    }
  }
  .next_arrow {
    width: 40px;
    position: relative;
    -moz-animation: bounce 1s ease-in-out infinite;
  -webkit-animation: bounce 1s ease-in-out infinite;
  animation: bounce 1s ease-in-out infinite;
  }
  .speaker {
    img {
      width: 35px;
      height: 30px;
    }
  }
}


@keyframes bounce {
  0%,
  100% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(0, 15px);
  }
}