// @import url("https://fonts.googleapis.com/css?family=Montserrat:300,300i,400,400i,700,700i&display=swap");

@import "./fonts/stylesheet.css";

// import Style for Mobile
@import "./components/MobileView/styles/style.scss";

@import "./_vars.scss";
body {
  overflow: hidden;
}

html {
  -webkit-text-size-adjust: none;
  touch-action: manipulation;
}

.App,
#root,
body,
html {
  overflow: hidden;
  position: relative;
}

/* html{
  overflow-x: hidden;
  overflow-y: scroll;
  position: relative;
} */

#fullscreenToggle {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 0;
  left: 0;
  background-color: transparent;
  //cursor: pointer;
}

#loaderIndication {
  display: block;
  color: #000000;
  font-size: 30px;
  text-align: center;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background-color: #043b5b;
  .loader {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 179px;
    height: 260px;
  }
  .stagr-logo {
    position: absolute;
    display: block;
    top: 50px;
    left: 8%;
    //right:0;
    //margin: auto;
    width: 20vw;
    max-width: 409px;
    min-width: 200px;
    cursor: pointer;
  }
}

/* #envHolder{
  padding:0;
  width: 100vw;
  height: calc(100vw * 2 / 3);
  margin: auto;
  position: absolute;
  top: 0px; right: 0px; bottom: 0px; left: 0px;
}

@media (min-width: 150vh) {
  #envHolder {
    height: 100vh;
    width: calc(100vh * 3 / 2);
  }
}  */
#envHolder {
  position: relative;
  display: block;
  transform: translateZ(0);
  overflow: hidden;
  opacity: 0;
  #myEnvVideo {
    width: 100%;
    transform: translateZ(0);
  }
  .top-gradient {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 200px;
    pointer-events: none;

    &:before {
      transition: opacity 1.5s;
      -webkit-transition: opacity 1.5s;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 200px;
      pointer-events: none;

      background: -moz-linear-gradient(
        top,
        rgba(255, 255, 255, 1) 5%,
        rgba(255, 255, 255, 0) 100%
      );
      background: -webkit-linear-gradient(
        top,
        rgba(255, 255, 255, 1) 5%,
        rgba(255, 255, 255, 0) 100%
      );
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 1) 5%,
        rgba(255, 255, 255, 0) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=0 );
      opacity: 1;
      z-index: 0;
    }

    &:after {
      transition: opacity 1.5s;
      -webkit-transition: opacity 1.5s;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 200px;
      background: -moz-linear-gradient(
        top,
        rgba(0, 0, 0, 1) 5%,
        rgba(0, 0, 0, 0) 100%
      );
      background: -webkit-linear-gradient(
        top,
        rgba(0, 0, 0, 1) 5%,
        rgba(0, 0, 0, 0) 100%
      );
      background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 1) 5%,
        rgba(0, 0, 0, 0) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#00000000',GradientType=0 );
      pointer-events: none;
      opacity: 0;
      z-index: 0;
    }

    &.black {
      &:before {
        opacity: 0;
      }
      &:after {
        opacity: 1;
      }
    }
  }
  .stagr-logo {
    position: absolute;
    display: block;
    top: 50px;
    left: 8%;
    //right:0;
    //margin: auto;
    width: 20vw;
    max-width: 409px;
    min-width: 200px;
    cursor: pointer;
  }
  #speaker {
    position: absolute;
    padding: 0;
    bottom: 40px;
    right: 40px;
    cursor: pointer;
    width: 50px;
    opacity: 0.8;
    .speakerBtn.off {
      display: none;
    }
    &.muted {
      opacity: 0.25;

      .speakerBtn.off {
        display: block;
      }
      .speakerBtn.on {
        display: none;
      }
    }
    &.unmuted {
      opacity: 0.8;

      .speakerBtn.off {
        display: none;
      }
      .speakerBtn.on {
        display: block;
      }
    }
    &:hover {
      opacity: 1;
    }
  }
  #pollenHolder {
    display: none;

    .pollenButtonHolder {
      transform: translateZ(0);
      position: absolute;
      display: block;
      top: 0;
      left: 0;

      .pollenButton {
        transform: translateZ(0);
        display: block;
        border-radius: 100px;
        padding: 15.1px 97px 17.2px 194.4px;
        line-height: normal;
        text-align: left;
        color: #000000;
        font-size: 27px;
        font-family: "Montserrat", sans-serif;
        vertical-align: middle;
        background-color: #ffffff;
        cursor: pointer;
        max-height: 68px;
        margin: 0;
        //transition: box-shadow 0.2s;
        //-webkit-transition: box-shadow 0.2s;

        @media (max-width: $xl) {
          font-size: 1.25vw;
          padding: 0.7vw 4.5vw 0.8vw 9vw;
        }

        .pollenPlus {
          position: absolute;
          display: block;
          right: 382px;
          top: 0;
          bottom: 0;
          right: 1%;
          margin: auto;
          width: 60px;
          height: 60px;
          max-width: 60px;
          max-height: 60px;
          @media (max-width: $xl) {
            width: 2.5vw;
            height: 2.5vw;
          }
          #pollenPlus_check__pollenPlus {
            display: none;
          }
        }
        .thumb {
          position: absolute;
          display: block;
          left: calc(-100% + 172px);
          top: 0;
          bottom: 0;
          right: 0;
          margin: auto;
          width: 172px;
          height: 172px;
          max-width: 172px;
          max-height: 172px;
          border-radius: 100px;
          border: 4px solid #cbd34b;
          background-position: center;
          background-size: 105%;
          transition: background-size 0.2s;
          -webkit-transition: background-size 0.2s;

          @media (max-width: $xl) {
            width: 8vw;
            height: 8vw;
            left: calc(-100% + 8vw);
          }
        }
        &:hover {
          //-webkit-box-shadow: 0 0 0 4px rgba($green,1);
          //box-shadow: 0 0 0 4px rgba($green,1);
          .thumb {
            background-size: 125%;
          }
        }
        &.shadow_floor {
          &:after {
            content: "";
            background-image: url("./images/shadow.png");
            width: 110%;
            background-size: 100%;
            background-repeat: no-repeat;
            height: 13vw;
            position: absolute;
            display: block;
            top: 130%;
            left: -2vw;
            right: 0;
            margin: auto;
            opacity: 0.4;
            pointer-events: none;
          }
        }

        &.visited {
          .pollenPlus {
            #pollenPlus_check__pollenPlus {
              display: block;
            }
            #pollenPlus_plus__pollenPlus {
              display: none;
            }
          }
        }
      }
    }
  }
  #navigation {
    position: absolute;
    display: block;
    left: 0;
    right: 0;
    margin: auto;
    bottom: 4vw;
    width: 10vw;
    max-width: 213px;
    height: auto;
    visibility: hidden;
    opacity: 0;
    .nav {
      cursor: pointer;
      .st0 {
        transition: opacity 0.2s;
        -webkit-transition: opacity 0.2s;
      }

      svg {
        position: relative;
        display: block;
        width: 10vw;
        height: auto;
      }

      &.disabled {
        cursor: default;
        opacity: 0.1;
        pointer-events: none;
      }
      &:hover,
      &:active {
        .st0 {
          opacity: 0.5;
        }
      }
    }
  }
}

#footer {
  position: absolute;
  display: block;
  left: 10px;
  bottom: 10px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
  text-shadow: 0 0 5px #000000;
}
