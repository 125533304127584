@import './_vars.scss';
#menuHolder{	
	background: transparent;
	#menu{
		position:absolute;
		top: 0;
		left:0;
		display:none;
		width: 100%;
		height: 100%;
		background-color: $blue;
		padding:10%;
		-moz-transform: translateX(100%);
		-webkit-transform: translateX(100%);
		-o-transform: translateX(100%);
		-ms-transform: translateX(100%);
		transform: translateX(100%);
		.instr {
			position: absolute;
		    display: block;
		    border: 1px solid #CBD34B;
		    width: 100%;
		    padding: 8px;
		    max-width: 29vw;
		    top: 17vh;
		    left: 9.2%;
			p{							
				font-weight: 400;
			    font-size: 0.98vw;
			    color: #CBD34B;
			    margin: 0;
			    line-height: normal;
			    text-align: center;
			}
		}
		#MapMenu{
			position: absolute;
		    width: 80%;
		    left: 0;
		    right: 0;
		    top: 0;
		    bottom: 0;
		    margin: auto;
		    height: 27vw;
			svg{
				width: 100%;
				height: auto;

				.item{			
					text{pointer-events:none;}			
				}
				.step{
					cursor: pointer;
					&:hover{
						.arrow{
							fill:#ffffff;					
						}
						.base ellipse{
							stroke:#ffffff;
							stroke-width: 6;					
						}				
					}
					.ring{
							pointer-events:none;													
						}
					.arrow{
						transition: fill 0.2s;
						-webkit-transition: fill 0.2s;								
						}
					.base ellipse{
						transition: stroke 0.2s, stroke-width 0.2s;
						-webkit-transition: stroke 0.2s, stroke-width 0.2s;
					}
					&.active{
						.base ellipse{
							stroke:#CBD34B;
							stroke-width: 6;					
						}	
						.ring{									
							circle{
								fill:rgba(255,255,255,0.1);
								stroke:#CBD34B;
								stroke-width: 3;
							}
						}
						.st10,.st11,.st5{
							stroke:#CBD34B;	
							fill:#CBD34B;
						}
						.arrow{
							fill:#CBD34B;
						}
						text,.line path, .line circle{
							fill:#CBD34B;
							stroke:#CBD34B;	
						}
					}

					

				}
				.pollen{
					cursor: pointer;			
					
					.st5{				
						transition: fill 0.35s, opacity 0.35s;
						-webkit-transition: fill 0.35s, opacity 0.35s;								
					}
					.base {				
						.st4{				
							stroke-width: 1;
							stroke:#ffffff;	
							transition: stroke 0.35s, stroke-width 0.35s;
							-webkit-transition: stroke 0.35s, stroke-width 0.35s;
						}
					}
					.check{				
						opacity: 0;								
					}
					.plus{			
						opacity: 1;										
					}
					line{
						pointer-events:none;
					}

					&:hover{
						.st5{
							fill:#ffffff;					
						}
						.base .st4{
							stroke:#ffffff;					
							stroke-width: 3;
						}
					}
					&.visited{
						.check{opacity: 1;}
						.plus{opacity: 0;}
						.base .st4{
							stroke:#CBD34B;					
							stroke-width: 3;
						}
					}

				}
				.path_grow{
					stroke-width:6px;			
				}
			}
		
			
		}
	}

	.stagr-logo{
		position: absolute;
		display: block;
		top: 50px;
		left: 9%;
		width:20vw;
		max-width: 409px;
		min-width: 200px;
		cursor: pointer;    
	}

	#menuButton{
		color:$white;
		position: absolute;
		display: block;
		top:40px;
		right:6%;		
		cursor: pointer;
		padding: 20px;
		width:17vw;	

		svg{
			width: 100%;
		    max-width: 120px;
		    min-width: 90px; 
		    float: right;
		}	
		#menuLines__menuButton{
			transition: transform 0.2s;
			-webkit-transition: transform 0.2s;
		}


		&:hover{
			#menuLines__menuButton{
				/*fill:#069;*/
				transform: scale(0.5,1);	
			}
			
		}
		&.hide,&.disable{
			visibility: hidden;
			opacity:0;
			pointer-events: none;
		}

		transition: opacity 0.25s;
		-webkit-transition: opacity 0.25s;
		


		

	}
	#menuCloser{
		color:$green;
		position: absolute;
		display: block;
		top: 0px;
    	right: 60px;
		font-size: 60px;
		font-weight: normal;
		cursor: pointer;
		padding: 40px;
		
		span{
			font-size: 20px;
		    font-weight: 700;
		    vertical-align: middle;
		    margin: -6px 10px 0 0;
		    position: relative;
		    display: inline-block;			
		}
		
		&:hover{
			color:$white;
		}
		&.hide{
			visibility: hidden;
			opacity:0;
			pointer-events: none;
		}

		transition: opacity 0.25s;
		-webkit-transition: opacity 0.25s;
	}
}