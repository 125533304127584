/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on August 15, 2019 */



@font-face {
    font-family: 'montserratbold';
    src: url('montserrat-bold-webfont.woff2') format('woff2'),
         url('montserrat-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'montserratbold_italic';
    src: url('montserrat-bolditalic-webfont.woff2') format('woff2'),
         url('montserrat-bolditalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'montserratlight';
    src: url('montserrat-light-webfont.woff2') format('woff2'),
         url('montserrat-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'montserratlight_italic';
    src: url('montserrat-lightitalic-webfont.woff2') format('woff2'),
         url('montserrat-lightitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'montserratmedium';
    src: url('montserrat-medium-webfont.woff2') format('woff2'),
         url('montserrat-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'montserratmedium_italic';
    src: url('montserrat-mediumitalic-webfont.woff2') format('woff2'),
         url('montserrat-mediumitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}