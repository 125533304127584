// import Styles
@import "./Header.scss";
@import "./Home.scss";
@import "./BottomBar.scss";
@import "./PollenLayout.scss";
@import "./PollenCard.scss";
@import "./InfoComponent.scss";
@import "./Footer.scss";
@import "./PopUp.scss";
@import "./Carousel.scss";
@import "./variables.scss";
@import "./MobileButton.scss";

body {
  padding: 0;
  margin: 0;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.3;
  font-family: $body_fonts;
  p {
    font-size: 18px;
  }
}
.App,
#root,
body,
html {
  @media (max-width: 767px) {
    overflow: auto !important;
  }
}

.mobile_wrapper {
  position: relative;
  height: 100vh;
  overflow: auto;
}

.container-fluid {
  padding-left: 30px;
  padding-right: 30px;
  width: 100%;
  max-width: 100%;
  @media (max-width: $mobile_lg) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

ul,
ol {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.bg_shadow,
.white_gr {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  display: none;
  z-index: -1;
  img {
    width: 100%;
    height: 100px;
  }
}

// CSS for Header Bg Shadow
body {
  &.header_shadow {
    .bg_shadow {
      display: block;
    }
    .menu_btn_wrap {
      h4 {
        color: $light;
      }
    }
    .logo {
      img {
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s,
        opacity 0.5s linear;
      }
      .logo_white {
        visibility: visible !important;
        opacity: 1 !important;
      }
    }
    .pollen_wrap {
      h3 {
        color: $light;
      }
    }
  }
}

#loaderIndicationMobile {
  background: #033b5b;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99999;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
// Global CSS End //
