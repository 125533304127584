@import "./variables.scss";

.pollen_wrap {
  h3 {
    font-size: 50px;
    font-weight: 300;
    line-height: 1.2;
    margin-bottom: 65px;
    color: $blue-text;
    @media (max-width: $mobile_lg) {
      margin-bottom: 35px;
      font-size: 42px;
    }
    @media (max-width: $mobile_sm) {
      font-size: 40px;
      margin-bottom: 30px;
    }
  }

  .pollenAnimate {
    .card_wrap {
      transform: scale(1);
    }
  }

  &.header_shadow {
    h3 {
      color: $light;
    }
  }
}
