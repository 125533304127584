@import "./variables.scss";

.header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 100;
  padding: 13px 0;
  max-width: 100%;
  box-sizing: border-box;
  z-index: 999;
  transition: 0.4s all ease;
  .logo {
    @media (max-width: $mobile_sm) {
      width: 220px;
      img {
        width: 100%;

      }

    }
    img {
      visibility: visible;
      opacity: 1;
      transition: visibility 0s, opacity 0.5s linear;

    }
    .logo_white_op {
      opacity: 0;
      visibility: hidden;
    }
    .logo_white {
      visibility: visible;
      opacity: 1;
      transition: visibility 0s, opacity 0.5s linear;
    }
  }

  .menu_btn_wrap {
    text-align: center;
    width: 40px;
    margin-left: auto;
    h4 {
      font-size: 12px;
      font-weight: 700;
      color: $blue-text;
      margin-bottom: 0;
      text-transform: uppercase;
    }
  }
  .menu_btn {
    background-color: $blue-text;
    width: 40px;
    height: 40px;
    padding-top: 10px;
    margin-bottom: 3px;
    span {
      width: 22px;
      height: 3px;
      background-color: $light;
      display: block;
      margin-bottom: 5px;
      position: relative;
      left: 8.5px;
      &:last-child {
        width: 13px;
        margin-bottom: 0;
      }
    }
  }

  .custom_nav {
    position: fixed;
    right: -100%;
    top: 0;
    background-color: $blue;
    padding-top: 20px;
    width: 100%;
    height: 100%;
    padding-left: 30px;
    padding-right: 30px;
    padding: 15px 30px 30px 30px;
    height: 100vh;
    overflow: auto;
    transition: 0.4s all ease;
    @media (max-width: $mobile_lg) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  .open_nav {
    right: 0;
  }
  .menu_row {
    height: 50px;
    justify-content: space-between;
  }
  .close_btn {
    cursor: pointer;
    width: 40px;
    height: 40px;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .menu_list {
    margin: 35px 0 100px;
    > li {
      padding-left: 25px;
      padding-bottom: 30px;
      position: relative;
      &:before {
        content: "";
        width: 20px;
        height: 1px;
        background-color: $light;
        position: absolute;
        left: 0;
        top: 12px;
        display: inline-block;
      }
      &:after {
        content: "";
        width: 1px;
        height: 100%;
        background-color: $light;
        position: absolute;
        left: 0;
        top: 12px;
        display: inline-block;
      }
      &:last-child {
        padding-bottom: 0;
        &:after {
          content: none;
        }
      }
    }
    
    .menu_title {
      font-size: 24px;
      font-weight: 700;
      color: $green;
      line-height: 1.1;
      display: inline-block;
      text-transform: uppercase;
      margin-bottom: 25px;
      @media (max-width: $mobile_sm) {
        font-size: 20px;
      }
    }
  }
  .menu_link {
    display: flex;
    cursor: pointer;
    font-size: 18px;
    color: $light;
    text-transform: capitalize;
    @media (max-width: $mobile_sm) {
      font-size: 16px;
    }
    .icon {
      width: 23px;
      height: 23px;
      flex: 0 0 23px;
      margin-right: 7px;
      background-color: transparent;
      color: transparent;
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC4AAAAuCAYAAABXuSs3AAAAAXNSR0IArs4c6QAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAALqADAAQAAAABAAAALgAAAABxWgY+AAAEz0lEQVRoBdVZzW8bRRSfdVCRUiG2hA/RQ7IgceiF+oLUQxu7ElSlUkUkkDhAFB8QBygid1TZ5ePc9OOEkLBJuSFU2kNLJYjj/gG18w/EdnqBlDaVKE2UxsvvN53njNeund3E7uZJz29mZ957v3n79u3s2FHbJN/3kzCRAlN6RrqQNhXRqYLL4HnHcSgHTwDrgbPgRXAUWoTSWbAXFb0TRtE4ykInI3q3by815uf+TCxUymphoawqkPdXVmRYPe+66uDBpBod89T4eFodSaXV6OhYcxyNPPgM7kIVcucJoLPgJl36Ke8ffyftD+9RoZl61A9QFv1gikVfCIx56+vrC+KEDg+84YUG22mBtGMvYGNjowI/yehojSaNgO8RdK1WjRzhTqDta7wDSDW68RuNxgpEOjJ4KGdoiHT1ymX/1ZfdHYmyDdhu074dfbjNhAYPJUZaE43ZDvrdDoCf2DJ4oGWp0+kxaNASFAGPnL8PLFvLeUy8BdbpIYaehhTwBk/3aoNJOYLmg9jvnO4VDPqXB5a4ginTfAFh0MPgIie8e+yoKs0X2QxFw8ND6uT7LS8Xrb/68JH67Zd6KFucPI6X1bUbc6L3mv2SsoHnMWPq59mC+vSTjEwOJUdeelb9ev3tNp3lv1bVByf+aLu+lQvf/5BXH01OcWoBwDOik2DDRFuPfvdNTsZiIS08UwanxqWBo5Vhj9FGfrMZGyIe4jI0LQ0BrqN9aTYv12MlL16YETzvSSOB8LNOektLdT/KAymG+im586zXa3TBdwzxKkY8zUapONd8UNmPG93crHJpYiNwvQKuKs5UKhUFXpqNZ8AeG/wI6EWs08N7qdKZ9o3sUcrx2waHhpR6EaWyG91ZXus2rOqbRcPlRAc5cw/S3f/KvpYvl05WPpx8XX02faDT0LavnUj9rh78+6irnQdrj4OCeu4wVfQK7M+trtoxGSTwXUm7Hzi/xncTsUTMg1M8Quj1AuIub/nv1SeuL5Fw1Asj7dWj0fDV3X+6V432WtTqhjtFQ8Sry2EVMsVzj17ErWm37emTdod376xF3h0KJgvfCq8xx8ts8LAmzmThKxIngevG4fEU+7ElC1+RIBOo5Yx4bYxHZJt5xLHY0Jt4/ogPVDN4dcR54TJ/Pp7MUMSOTn0xLZg0Tnakjs+ww08kszJ2Y0Es0+bTjXg0TjY0cIS/inaBF746naOIDVl4CganxiYRZyfHH64uLrnO3P781JeERWpGmx2HP0LYKebQznJvfhxHFFE2Xnufa9/2+ni7/Ndj5ycYRDJFrt+YUwQPOodoT8tYmwRwF6yPTWcLPw70zDB4QET/hojHbQMbvIBJSRz18szOf1rgcSRB9zxy3vrZIRcCnQmtiZ9Bg//266y4pswEA9uzTyWxQPD9PkukfSs9ooGWVUF7QtKmUr7lH3or2Ze8p13aJxl/4SMtoEXCVhKsH1gaZlR28j+gC+fP0qwQ/egyIv63JWHMBT9+YowLLiDqHaBeIC1odQbcu3qYlbTU8V6rg2EPc3LgKbAmnu3xsIbnHjxCuFnS+3wZ1vIIdp72/5yBbQXf2DOo0+UWpX50uABGCPlYgwxNRo8R9qLiCxXxTk7gnDmZNsxb3WljX8H1KrhIHkh04SiW9D/JZuo58Yb94AAAAABJRU5ErkJggg==);
      background-repeat: no-repeat;
      background-size: cover;
      border: 0;
      // width: 22px;
      // height: 22px;
      // flex: 0 0 22px;
      // margin-right: 7px;
      // border: 1px solid $light;
      // border-radius: 50%;
      // font-size: 22px;
      // color: $green;
      // font-weight: 700;
      // background-color: $dark;
      // line-height: 21px;
      // text-align: center;
      // display: flex;
      // align-items: center;
      // justify-content: center;
      // @media (max-width: $mobile_lg) {
      //   background-color: transparent;
      //   color: transparent;
      //   background-image: url("../../../images/icon-plus-2x.png");
      //   background-repeat: no-repeat;
      //   background-size: cover;
      // }
    }
  }
  .sub_menu {
    li {
      padding-bottom: 25px;
      position: relative;
      &:last-child {
        padding-bottom: 0;
      }
      &:before {
        content: "";
        width: 1px;
        height: 100%;
        background-color: #fff;
        position: absolute;
        left: 11px;
        top: 0;
        display: inline-block;
        z-index: -1;
      }
      @media (max-width: $mobile_sm) {
        padding-bottom: 17px;
      }
    }
  }

  // CSS for Header Shadow
  &.header_shadow {
    .bg_shadow {
      display: block;
    }
    .menu_btn_wrap {
      h4 {
        color: $light;
      }
    }
  }
  &.home_shadow {
    .white_gr {
      display: block;
    }
  }
}
