@import '/_vars.scss';
.modal{
	overflow: hidden !important;
	.modal-dialog{
		max-width: 1850px;
    	margin: 0 auto;
    	padding: 50px;
    	.modal-content{
			-webkit-border-radius: 0 60px 0 60px;
			border-radius: 0 60px 0 60px;
			padding:70px 50px 50px 50px;
			max-height: calc(100vh - 120px);
			.closer{
				position: absolute;
				top: 0px;
				right:0px;
				font-size: 50px;
				color:$blue;
				cursor: pointer;
				padding: 40px 42px;
			    line-height: 0;
				&:hover,&:active{
					color:$orange;
				}
				span{
				    font-size: 20px;
				    font-weight: 700;
				    vertical-align: middle;
				    margin: -6px 10px 0 0;
				    position: relative;
    				display: inline-block;
				}

			}
			.container{
				max-width: 1750px;
				overflow-y: scroll;
				overflow-y: scroll; /* has to be scroll, not auto */
  				-webkit-overflow-scrolling: touch;
				::-webkit-scrollbar{display:none !important;}
				::-webkit-scrollbar { width: 0 !important; }
				 overflow: -moz-scrollbars-none;
				 -ms-overflow-style: none;
				 height: 75vh;
				h1{
					font-size: 55px;
					font-family: 'Montserrat', sans-serif;
					font-weight: normal;
					color:$blue-text;
					border-bottom: 1px solid #707070;
					line-height: 44px;
					padding-bottom: 30px;
				}
				.left p{
					font-size: 18px;
					font-family: 'Montserrat', sans-serif;
					font-weight: normal;
					color: #202020;
					margin-bottom: 0;
					&.head{
						font-weight: 700;
					}
					&.copy{
						margin-bottom: 15px;
					}

				}
				.right p{
					font-size: 21px;
					font-family: 'Montserrat', sans-serif;
					font-weight: normal;
					font-weight: 400;
					color: #202020;
					margin-bottom: 0;
				}
				.images-holder{

					.pollen-image{
						outline: 1px solid #707070;
					    margin: 0 auto 30px auto;
					    position: relative;
					    display: block;
					    width:100%;
					}
				}
				.audio-holder{
					position: relative;
					display: block;
					.audio-title{
						color: $blue !important;
						font-weight: 700 !important;
						position: relative;
						margin-left:54px;
						line-height: normal;
					}
				}
				.react-h5-audio-player {
					background: transparent;
					.flex{
						box-shadow: none !important;
						background-color: transparent !important;
						padding: 0px 0px 15px 0 !important;
    					margin-top: -15px !important;
    					height: auto !important;

						.toggle-play-wrapper {
							width:50px !important;
							flex:none !important;

						    .toggle-play-button {
						      // Remember to use !important to overwrite inline styles.
						      background-color: $green !important;
						    	.play-icon{
						      		border-left:18px solid $blue !important;
						      		box-shadow: none !important;
					    		}
					    		.pause-icon{
					    			border-left:7px solid $blue !important;
					    			background-color: $blue !important;
    								box-shadow: $green 7px 0px 0px 0px inset !important;
					    		}
					    	}

					  	}
					  	.progress-bar-wrapper{
					  		padding: 28px 4% 0px 5px !important;
					  		.indicator{
					  			top:19px !important;
					  			-webkit-border-radius: 0!important;
								border-radius: 0!important;
								background: $blue !important;
								width:12px !important;
								box-shadow: none !important;
					  		}
					  		.audio-info{
					    		display: none !important;

					    	}
					    	.progress-bar{
				    			background: $green !important;
				    		}
					  	}
					}
				}
				.info_wrap{

					&.info_wrap_desktop{
						height:auto;
						border-radius:0 60px 0 60px;
						padding:45px 0 25px;
						background-image:url('/images/bg-modal-desktop.png') !important;
						background-position: 100% 100% !important;
						position: relative;
					&:before{
						position: absolute;
						content: '';
						background: rgba(0,0,0,0.2);
						width: 100%;
						height: 100%;
						top: 0;
						left: 0;
						z-index: 1;
						border-radius:0 60px 0 60px;
					}
						.info_content{
							display: flex;
							align-items:center;
							justify-content:center;
							max-width: 970px;
							margin: 0 auto;
							position: relative;
							z-index: 2;
							h3{
								width:calc(100% - 320px);
								line-height:1.1;
								@media screen and (max-width:1199px){
									font-size:38px;
									max-width: 420px;
								}
							}
							button{
								&.btn_success{
                                    width:320px;
								}
							}
						}
					}
				}
				.btn_info{
					width:320px;
					background-color: #CAD44B;
					color: #003D5B;
					border: none;
					padding: 10px 10px;
					border-radius: 20px;
					margin-top: 10px;
					font-weight:bold;
				}
			}
		}
	}
}



.btn_success{
width:320px;
background-color: #CAD44B;
color: #003D5B;
border: none;
padding: 10px 20px;
border-radius: 20px;
}
