@import "./variables.scss";

.home_wrap {
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: flex-end;
  padding: 50px 0 110px;
  z-index: 100;
  @media (max-width: $mobile_sm) {
    padding-bottom: 60px;
  }
  .container-fluid {
    position: relative;
    padding-top: 40px;
    z-index: 10;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .video_holder {
    width: 100%;
    min-height: 100vh;
    position: absolute;
    pointer-events: none;
    max-width: 100%;
    overflow: hidden;
    background-size: cover;
    background-position: top;
    top: 0;
    left: 0;
  }
  .video_holder img{
    width: 100%;
    height: 100vh;
  }
  #video {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-size: cover;
    background-position: center;
    width: 100%;
    z-index: 1;
  }
  #video[poster] {
    object-fit: cover;
  }
  .home_content {
    @media (max-width: $mobile_lg) {
      margin-bottom: 15%;
    }
    h2 {
      font-size: 60px;
      color: $light;
      font-weight: 300;
      line-height: 1.1;
      @media (max-width: $mobile_sm) {
        font-size: 44px;
      }
    }
    p {
      font-size: 20px;
      font-weight: 500;
      color: $light;
      line-height: 1.3;
      margin-bottom: 40px;
      @media (max-width: $mobile_sm) {
        font-size: 16px;
      }
    }
  }
}

/* custom to pasture slide */

/* pasture video */
#video {
  transform: scale(1) translateY(0) translateX(0);
  transition: all 2s 2s;
}
#video.Pasture {
  @media (max-width: $mobile_lg) {
    transform: scale(2.4) translateY(-40px) translateX(-109px);
  }
  @media (max-width: $mobile_sm) {
    transform: scale(2.4) translateY(-48px) translateX(-103px);
  }
  @media (max-width: $mobile_xsm) {
    transform: scale(2.4) translateY(-20px) translateX(-93px);
  }
}

.home_content_bg {
  background-image: url("../../../images/mobile/hero_bg.gif");
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: flex-start;
  padding: 50px 30px 110px;
  flex-direction: column;
  z-index: 100;
  justify-content: flex-end;
  background-size: cover;
  @media (max-width: $mobile_lg) {
    padding-left: 15px;
    padding-right: 15px;
  }
   h2 {
    font-size: 60px;
    color: #fff;
    font-weight: 300;
    line-height: 1.1;
}
 p {
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  line-height: 1.3;
  margin-bottom: 40px;
}
}