.carousel_wrap {
  position: relative;
  margin-bottom: 65px;
  .carousel-item {
    // border: 1px solid $grey;
    overflow: hidden;
    @media (max-width: $mobile_lg) {
      img {
        min-height: 303px !important;
      }
    }
  }
  .carousel-indicators {
    margin: 0;
    display: flex;
    justify-content: center;
    bottom: -33px;
    margin: 0 35px;
    li {
      width: 13px;
      height: 13px;
      border: 2px solid $success;
      background-color: transparent;
      border-radius: 50%;
      padding: 0;
      margin: 0 10px;
      opacity: 1;
      font-size: 0;
      &.active {
        background-color: $success;
      }
    }
  }
  .carousel-control-next,
  .carousel-control-prev {
    width: 20px;
    height: 30px;
    padding: 0;
    top: auto;
    bottom: -40px;
    opacity: 1;
    span {
      width: 20px;
      height: 30px;
      background-size: cover;
    }
  }
  .carousel-control-next {
    .carousel-control-next-icon {
      background-image: url("../../../images/arrow_right.png");
    }
  }
  .carousel-control-prev {
    .carousel-control-prev-icon {
      background-image: url("../../../images/arrow_left.png");
    }
  }
}
