@import "./variables.scss";

.card_wrap {
  position: relative;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  transition: 0.6s all ease;
  transform: scale(0);
  span {
    padding-left: 20px;
    display: inline-block;
    @media (max-width: $mobile_sm) {
      font-size: 13px;
    }
  }
  .thumb {
    width: 100px;
    height: 100px;
    flex: 0 0 100px;
    border-radius: 50%;
    border: 4px solid $green;
    background-position: center;
    background-size: 100%;
    transition: background-size 0.2s;
    -webkit-transition: background-size 0.2s;
    overflow: hidden;
    z-index: 5;
    position: relative;

    @media (max-width: $mobile_lg) {
      width: 85px;
      height: 85px;
      flex: 0 0 85px;
      border-width: 3px;
    }
    @media (max-width: $mobile_sm) {
      width: 60px;
      height: 60px;
      flex: 0 0 60px;
    }
  }
  .pollenButton {
    width: 100%;
    border-radius: 100px;
    padding: 4.5px 4.5px 5px 50px;
    text-align: left;
    cursor: pointer;
    height: 45px;
    margin: 0;
    z-index: 1;
    font-size: 17px;
    background-color: $light;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: -50px;
    // -webkit-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);
    // -moz-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);
    // box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);
    @media (max-width: $mobile_lg) {
      font-size: 15px;
      letter-spacing: -0.3px;
    }
    @media (max-width: $mobile_sm) {
      padding-left: 40px;
      height: 33px;
      letter-spacing: -0.3px;
    }
  }
  .pollenPlus {
    width: 40px;
    height: 40px;
    margin-left: auto;
    @media (max-width: $mobile_sm) {
      width: 32px;
      height: 32px;
      position: relative;
      top: 1px;
      right: -2px;
    }
    img {
      width: 100%;
    }
  }
}
